import React, { useState } from "react";
import Tabs from "../TabsRedux";
import { motion } from "framer-motion";
import { Button, Dropdown, Popup, Icon } from "semantic-ui-react";
import { uniqBy } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import config from "../../configData";
import {
  selectAdmin,
  currentDocumentMaster,
} from "../../Store/admin";
import {
  selectFirestore,
  getSearchStartsWithFilter,
} from "../../Store/firestore";
import "./index.scss";

const { searchTypesArr, langArr, dataTypeToCompontent } = config();

const defaultLang = "English";
const defaultSearchCollections = ["Words"];

const AdminDictionary = () => {
  const Dispatch = useDispatch();
  const fireStoreData = useSelector(selectFirestore);
  const AdminStoreData = useSelector(selectAdmin);
  const [showSearch, setShowSearch] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [newType, setNewType] = useState(null);
  const [showNewMenu, setShowNewMenu] = useState(false);
  const [searchType, setSearchType] = useState(["Words"]);
  // eslint-disable-next-line no-unused-vars
  const [langType, setLanguageType] = useState("English");
  const [showAdvSearch, setshowAdvSearch] = useState(false);

  const handleSelection = (_e, data) => {
    setShowSearch(false);
    setShowTabs(true);
    let document = fireStoreData.searchDataResults.find(
      (el) => el.id === data.value
    );
    Dispatch(currentDocumentMaster(document));
  };

  const reset = () => {
    setShowSearch(false);
    setShowTabs(false);
    setShowNewMenu(false);
    setNewType(null);
    Dispatch(currentDocumentMaster({}));
  };

  const newMenuDOM = () => {
    let types = [];
    for (const key in dataTypeToCompontent) {
      types.push(key);
    }
    return (
      <motion.div
        className="adminDictionary-newMenu"
        key="adminDictionary-newMenu"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          hidden: {
            scale: 1,
            opacity: 0,
            y: -30,
          },
          visible: {
            scale: 1,
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.1,
              duration: 0.9,
              type: "spring",
            },
          },
        }}
      >
        {types.map((ele) => (
          <Button
            key={ele}
            onClick={() => {
              setNewType(ele);
              setShowTabs(true);
            }}
          >
            {ele}
          </Button>
        ))}
      </motion.div>
    );
  };
  const headerBarDOM = () => {
    if (AdminStoreData?.currentDocumentMaster?.id?.length > 1) {
      return (
        <>
          <Button color="red" onClick={reset}>
            Cancel
          </Button>
        </>
      );
    } else {
      return (
        <>
          {!showNewMenu ? (
            <Button
              onClick={() => {
                setShowNewMenu(true);
              }}
            >
              Create New
            </Button>
          ) : (
            <Button
              color="red"
              onClick={() => {
                setShowNewMenu(false);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            onClick={() => {
              setShowSearch(true);
            }}
          >
            Edit Existing
          </Button>
          {fireStoreData.searchDataResults && (
            <Dropdown
              text="Recent"
              labeled
              button
              onChange={handleSelection}
              floating
              className="icon"
              options={
                fireStoreData &&
                fireStoreData.searchDataResults &&
                formatDataForDropDown(fireStoreData.searchDataResults)
              }
            />
          )}
        </>
      );
    }
  };
  const searchDOM = () => {
    return (
      <>
        <div className="dictionary-single-line-search">
          <div className="dictionary-sl-left">
            <div className="dictionary-sl-dropdown-search-lang">
              <div id="dictionary-sl-dropdown-search-cont">
                <Dropdown
                  selection
                  placeholder="Search"
                  search
                  fluid
                  minCharacters={3}
                  noResultsMessage="No results found"
                  onChange={handleSelection}
                  onSearchChange={(_e, data) => {
                    Dispatch(
                      getSearchStartsWithFilter({
                        searchString: data.searchQuery,
                        collectionNames: [...searchType],
                      })
                    ); //TODO: maybe move this to a state store so that it can get cleared nicer?
                  }}
                  options={
                    (fireStoreData &&
                      fireStoreData.searchDataResults &&
                      formatDataForDropDown(fireStoreData.searchDataResults))
                    || []
                  }
                />
              </div>
              <div>
                <Popup
                  content="Langauge Selection"
                  trigger={
                    <Dropdown
                      // placeholder="Language"
                      defaultValue={defaultLang}
                      fluid
                      selection
                      options={langArr}
                      onChange={(_e, d) => {
                        setLanguageType(d.value);
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className="dictionary-sl-dropdown-type-cont">
              <Popup
                content="Search Types"
                position="top center"
                trigger={
                  <Dropdown
                    // placeholder="Search Type"
                    defaultValue={defaultSearchCollections}
                    fluid
                    multiple
                    minCharacters={10}
                    selection
                    options={searchTypesArr}
                    onChange={(_e, data) => {
                      // data.value is an array of selections
                      setSearchType(data.value);
                    }}
                  />
                }
              />
            </div>
            <div className="dictionary-sl-adv">
              <Button
                labelPosition="left"
                size="small"
                onClick={() => {
                  setshowAdvSearch(!showAdvSearch);
                }}
              >
                <Icon name={showAdvSearch ? "chevron down" : "chevron up"} />
                Advanced
              </Button>
              {showAdvSearch && (
                <div className="dictionary-sl-adv-menu">something</div>
              )}
            </div>
          </div>
          <div className="dictionary-sl-right">
            <div>
              <Button content="Waawakinoto" />
            </div>
          </div>
        </div>
      </>
    );
  };
  const tabsDOM = () => {
    return <Tabs type={newType} />;
  };

  setTimeout(() => {
    // Dispatch(changeUserData({newUser: true}));
  }, 900);

  return (
    <motion.div
      key="adminDictionary"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        hidden: {
          scale: 1,
          opacity: 0,
          y: -500,
        },
        visible: {
          scale: 1,
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.4,
            duration: 0.5,
            type: "spring",
          },
        },
      }}
    >
      <h1>Dictionary</h1>
      <div className="Admin-Dictionary-Header-Bar">{headerBarDOM()}</div>
      <div className="Admin-search-container">
        {showSearch ? searchDOM() : null}
        {showTabs ? tabsDOM() : null}
        {showNewMenu ? newMenuDOM() : null}
      </div>
    </motion.div>
  );
};

function formatDataForDropDown(dataFromDatabase) {
  let formatedDatalist = dataFromDatabase.map((d) => {
    return {
      key: d.id,
      text: d.data.English,
      data: d,
      value: d.id,
      content: (
        <div>
          {d.data.English} - {d.data["Grammatical_Category"]}
        </div>
      ),
    };
  });
  let newlist = uniqBy(formatedDatalist, "key"); //TODO: remove This should now be redundent the check is being done in the store now
  if (typeof newlist === "undefined") {
    return null;
  }
  return newlist;
}

// const createNewPane = () => {};

export default AdminDictionary;
