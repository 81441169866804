import "./index.scss";
import React, { useState, useEffect } from "react";
import { Icon, } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { selectIsLoggedIn } from "../../Store/loggedIn";
// import { selectAdmin } from "../../Store/admin";
import { useSelector } from "react-redux";

const aniVariats = {
  loggedIn: { rotateX: [0, 180, 0] },
  loggedOut: { scale: [0, 1], opacity: [0, 1] },
};

const MenuItem = (props) => {
  let active = props.activeTab === props.activeName ? "navigation-active" : "";
  let classes = props.verticalMenu
    ? `navigation-vertical-item`
    : `navigation-item ${active}`;
  if (!props.link) {
    return (
      <div className={classes}>
        <div name={props.activeName} onClick={props.nav}>
          <div className="navigation-text">{props.text}</div>
        </div>
      </div>
    );
  } else {
    return (
      <Link
        onClick={props.nav}
        to={{ pathname: `/${props.activeName}` }}
        className={classes}
      >
        <div className="navigation-text">{props.text}</div>
      </Link>
    );
  }
};

const Navigation = (props) => {
  const [showBar, setShowBar] = useState(false);
  // const adminData = useSelector(selectAdmin);
  const [userPhotoURL, setuserPhotoURL] = useState("");
  const loggedIn = useSelector(selectIsLoggedIn);
  const animationControls = useAnimation();
  let userTxt = "Log In";
  // console.log("→ clientConfig ", props.clientConfig)
  if (props.clientConfig.loginLangTxt) {
    userTxt = props.clientConfig.loginLangTxt;
  }
  const openSite = (url) => {
    setShowBar(false);
    window.open(url, "_blank");
  };
  const navigate = (tab) => {
    props.setActiveTab(tab);
    setShowBar(false);
  };
  if (loggedIn.isLoggedIn) {
    userTxt = "Logged in as " + loggedIn?.currentUserData?.user;
  }
  const [menuArray, setMenuArray] = useState([
    {
      link: true,
      text: props.clientConfig.tabNameWelcome,
      activeName: "welcome",
      nav: () => navigate("welcome"),
    },
    {
      link: true,
      text: props.clientConfig.tabNameDictionary || "unknown",
      activeName: "dictionary",
      nav: () => navigate("dictionary"),
    },
    {
      link: true,
      text: "Media Library",
      activeName: "ML",
      nav: () => navigate("ML"),
    },
    {
      link: true,
      text: (
        <motion.div
          variants={aniVariats}
          animate={animationControls}
          className={loggedIn ? "nav-log-button-active" : "nav-log-button"}
        >
          <Icon name="user" /> {userTxt}
        </motion.div>
      ),
      activeName: "login",
      nav: () => navigate("login"),
    },
  ]);

  useEffect(() => {
    if (loggedIn.isLoggedIn) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      userTxt = "Logged in as " + loggedIn?.currentUserData?.user;
      setuserPhotoURL(loggedIn?.currentUserData?.photoURL || "");
      animationControls.start("loggedIn");
    } else {
      animationControls.start("loggedOut");
    }

    let newMenuArray = menuArray.slice(0, -1);
    newMenuArray.push(
      {
        link: true,
        text: (
          <motion.div
            variants={aniVariats}
            animate={animationControls}
            className={loggedIn ? "nav-log-button-active" : "nav-log-button"}
          >
            <img src={userPhotoURL} width="15" alt="" /> {userTxt}
          </motion.div>
        ),
        activeName: "login",
        nav: () => navigate("login"),
      }
    )


    setMenuArray(newMenuArray);
  }, [loggedIn]);

  useEffect(() => {
    let clientMenuArray = props.clientConfig.linkArray;
    let loginLink = menuArray.find((link) => link.activeName === "login");
    let newMenuArray = menuArray.filter((link) => link.activeName !== "login");
    clientMenuArray.forEach((link) => {
      let newDatum = link;
      newDatum.nav =
        link.navType === "external"
          ? () => openSite(link.navText)
          : () => navigate(link.navText);
      newMenuArray.push(newDatum);
    });
    newMenuArray.push(loginLink);
    setMenuArray(newMenuArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.dims.width < 867) {
    let verticalMenu = true;
    if (showBar === true) {
      return (
        <AnimatePresence>
          <motion.div
            key="menu"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: {
                scale: 1,
                opacity: 0,
                x: -200,
                y: -40,
              },
              visible: {
                scale: 1,
                opacity: 1,
                x: 0,
                y: -40,
                zIndex: 9999,
                transition: {
                  delay: 0.4,
                  duration: 0.5,
                  type: "spring",
                },
              },
            }}
          >
            <div className="navigation-semantic-menu">
              <div
                className="navigation-close-button"
                onClick={() => setShowBar(false)}
              >
                Close
                <Icon name="close" />
              </div>
              {menuArray.map((datum) => {
                return (
                  <MenuItem
                    key={datum.text}
                    verticalMenu={verticalMenu}
                    link={datum.link}
                    text={datum.text}
                    activeTab={props.activeTab}
                    activeName={datum.activeName}
                    nav={datum.nav}
                  />
                );
              })}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    } else {
      return (
        <AnimatePresence>
          <div
            className="navigation-small-closed-menu"
            onClick={() => setShowBar(true)}
          >
            <Icon
              fitted
              className="navigation-small-closed-menu-icon"
              name="bars"
              size="large"
              inverted
            />
          </div>
        </AnimatePresence>
      );
    }
  } else {
    if (showBar === true) {
      setShowBar(false);
    }
    let verticalMenu = false;
    return (
      <div className="navigation-full-menu">
        {menuArray.map((datum) => {
          return (
            <MenuItem
              key={datum.text}
              verticalMenu={verticalMenu}
              link={datum.link}
              text={datum.text}
              activeTab={props.activeTab}
              activeName={datum.activeName}
              nav={datum.nav}
            />
          );
        })}
      </div>
    );
  }
};

export default Navigation;

