/*
  COPY THESE INSTRUCTIONS TO ALL NEW LANGUAGE CONFIG FILES (FOR EASY ACCESS)

Three files need their import statements altered for a Language Switch
1. App.scss - handles all styling changes
2. App.js - Enabling contents to change throughout the app to fit the Language
3. Store/firestore.js - Enables data from correct firestore database
  */

const tabNameWelcome = "kawóʔ ciklí‧n";
const groupName = "Colville";
const tabNameDictionary = "Dictionary";
const firebaseConfig = {
  apiKey: "AIzaSyBOCDfUIZ7V6JkoYPnH47lN3Juay7-mMNc",
  authDomain: "colville-tit.firebaseapp.com",
  databaseURL: "https://colville-tit.firebaseio.com",
  projectId: "colville-tit",
  storageBucket: "blackfootmedia",
  messagingSenderId: "649134109147",
  appId: "1:649134109147:web:7fcdcf50f427bd78a137fe",
  measurementId: "G-BJY62SWFH9",
};

//=====> This is the main bar <====//
const linkArray = [
];

const welcomeEngArray = [
  // "Hello, everyone.",
  // "I am glad to see you all.",
  // "I am happy that you have all come here.",
  // "It's a happy day",
  // "To try to speak Blackfoot.",
];

const welcomeLangArray = [
  // { text: "Oki kanaitapi", audio: "hello_everyone.mp3" },
  // { text: "Ihsukapi kitsinohtsi", audio: "glad_to_see_you_all.mp3" },
  // {
  //   text: "Tsiikohtaahsi'tsihp iihtootaaatsiiyo'p",
  //   audio: "i_am_happy_you_all_come_here.mp3",
  // },
  // { text: "Iitamiksistikoo", audio: "its_happy_day.mp3" },
  // { text: "Stamssaakiitsi'poyit", audio: "try_to_speak_blackfoot.mp3" },
];

const welcomeLogoArray = [
  "logo-nta",
];

const welcomeMessageArray = [
  "Developed by Native Teaching Aids, in conjunction with the Confederated Tribes of the Colville",
];

const config = {
  linkArray: linkArray,
  engArray: welcomeEngArray,
  langArray: welcomeLangArray,
  logoArray: welcomeLogoArray,
  messageArray: welcomeMessageArray,
  tabNameWelcome: tabNameWelcome,
  tabNameDictionary: tabNameDictionary,
  dictionaryTitle: tabNameDictionary,
  firebaseConfig: firebaseConfig,
};

export default config;
export { linkArray, welcomeEngArray, welcomeLangArray, firebaseConfig };
