import "./index.scss";
import React, { useState } from "react";
import { Header, Container, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import LeftBar from "./LeftBar";

const Lessons = (props) => {
  const [currentModule, setCurrentModule] = useState("Module 1");
  return (
    <div className="current-screen-style">
      <div className="lessons-container">
        <LeftBar
          currentModule={currentModule}
          setCurrentModule={setCurrentModule}
        />
        <div className="lessons-module-container">
          <div className="lessons-topHeader">Aitsskiniimaatstokit</div>
          <Header className="lessons-header" block attached="top">
            {currentModule}
          </Header>
          <Segment attached>
            <div className="lessons-moduleIntro">{currentModule} Intro</div>
            Map here...
            <Container className="lessons-semantic-container">
              <Header className="lessons-header" block attached="top">
                Lesson 1
              </Header>
              <Segment attached>
                <div>Lesson 1 Intro</div>
              </Segment>
              <Header className="lessons-header" block attached="bottom">
                <div className="lessons-beginLesson">Begin Lesson</div>
              </Header>
            </Container>
          </Segment>
        </div>
      </div>
    </div>
  );
};

export default Lessons;

