
// ==> client config <== //

// const langDir = "BF";
const langDir = "Colville-tit";
const langName = "Titoqatímt";
// const langName = "colvilleTit";
// ===================== //

const baseConfigDir = "./Config/";

export const fullConfigDir = baseConfigDir + langDir;

const keyboard = [
  "a", "a·", "c", "cˀ", "e", "é·", "h", "i", "í·", "k", "k’", "l", "l’", "ł", "ƛ", "m",
  "m’", "n", "n’", "o", "ó·", "p", "p’", "q", "qˀ", "s", "t", "tˀ", "u", "ú·", "w", "wˀ", "x",
  "x̂", "y", "yˀ",
]

const lookupTable = {
  a: ["a", "á", "áá", "aa"],
  b: ["b"],
  c: ["c"],
  d: ["d"],
  e: ["e"],
  f: ["f"],
  g: ["g"],
  h: ["h"],
  i: ["i", "í", "ii", "íí"],
  j: ["j"],
  k: ["k"],
  l: ["l"],
  m: ["m"],
  n: ["n"],
  o: ["o", "ó", "óó"],
  p: ["p"],
  q: ["q"],
  r: ["r"],
  s: ["s", "ss"],
  t: ["t"],
  u: ["u"],
  v: ["v"],
  w: ["w"],
  x: ["x"],
  y: ["y"],
  z: ["z"],
};

const searchTypesArr = [
  { key: "Noun", text: "Nouns", value: "Noun" },
  { key: "Verb", text: "Verbs", value: "Verb" },
  { key: "Affixes", text: "Affixes", value: "Endings" },
  { key: "Prefixes", text: "Prefixes", value: "Beginnings" },
  { key: "Phrases", text: "Phrases", value: "MultiwordPhrases" },
];

const langArr = [
  { key: "Language", text: langName, value: "Language" },
  { key: "English", text: "English", value: "English" },
];

const dataTypeToCompontent = {
  Words: "Words",
};

export const collectionsToDisplay = (collectionName) => {
  const collectionO = {
    I_Nouns: () => "Noun",
    Words: () => "Words",
    I_Verbs: () => "Verb",
  };

  if (typeof collectionO[collectionName] !== "function") {
    return collectionName; // display actual name if not in list.
  }
  return collectionO[collectionName]();
};

export const Show_Quiz = true;

function mediaDirSwitch(lang) {
  const langServerName = {
    "BF": "blackfootmedia",
    "Salish": "salishmedia",
    "Colville-nse": "colville-nse-media",
    "Colville-tit": "colville-tit-media",
  }
  const staticgoogleapis = "https://storage.googleapis.com/";
  return staticgoogleapis + langServerName[lang];
}

// const IMAGE_PRE = "https://storage.googleapis.com/blackfootmedia/Image/";
const IMAGE_PRE = mediaDirSwitch(langDir) + "/Image/";
const AUDIO_PRE = mediaDirSwitch(langDir) + "/Audio/";
// const AUDIO_PRE = "https://storage.googleapis.com/blackfootmedia/Audio/";

const config = () => {
  const clientConfigs = {
    Salish: {
      baseDir: "https://storage.googleapis.com/salishmedia",
      NativeLanguage: "Salish",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    },
    BF: {
      baseDir: "https://storage.googleapis.com/blackfootmedia",
      NativeLanguage: "Blackfoot",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
    },
    Titoqatímt: {
      baseDir: "https://storage.googleapis.com/colville-tit-media",
      NativeLanguage: "Titoqatímt",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
      PartsOfSpeech: [],
      showAdvancedSearchButton: false,
      dictionaryTitle: "Dictionary",
    },
    Nsəlxcin: {
      baseDir: "https://storage.googleapis.com/colville-nse-media",
      NativeLanguage: "Nsəlxcin",
      lookupTable: lookupTable,
      searchTypesArr,
      langArr,
      dataTypeToCompontent,
      IMAGE_PRE,
      AUDIO_PRE,
      dictionaryTitle: "Dictionary",
      PartsOfSpeech: [],
      showAdvancedSearchButton: false,
    }
  };
  return clientConfigs[langName];
};

export default config;
