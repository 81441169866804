import React, { useState, useEffect, useRef } from "react";

import config from "../../configData";
import {
  Form,
  Button,
  Input,
  Dropdown,
  Checkbox,
  Icon,
  Image,
} from "semantic-ui-react";
import InputControlled from "../InputControlled";
import { useSelector, useDispatch } from "react-redux";
import { selectFirestore, getOptions, setTags } from "../../Store/firestore";
import SimpleMediaPreview from "../Admin/SimpleMediaPreview";
import { formatOptionsForDropdown } from "../nonUIFuncs";
import TextEditor from "../TextEditor";
import useOnScreen from "../../Hooks/useOnScreen";

const { NativeLanguage: nativeLanguage } = config();

const WordCollection = ({ data }) => {
  // set record to active data from database will override making this a default
  const [formData, setformData] = useState({
    recordActive: true,
    ...data.data,
  });
  const [dataSaved, setdataSaved] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rootDD, setrootDD] = useState(
    formatOptionsForDropdown([data.data.Root])
  );
  const alwaysOnScreenHeader = useRef(null);
  const isVisible = useOnScreen(alwaysOnScreenHeader);
  const fireStoreData = useSelector(selectFirestore);
  const Dispatch = useDispatch();


  // let speaker; 
  useEffect(() => {
    let fieldsToPopulate = ["Category", "SUB.Plurality", "SUB.Person", "Grammatical_Category", "Subcategory", "Grammatical_Subclass", "Root.Type"];
    fieldsToPopulate.forEach((field) => {
      Dispatch(getOptions({ myCollection: "Words", field: field }));
    });

    if (typeof formData.speakers !== "object") {
      // ==> if object doesn't exist then use old data from spreadsheet entry and create objecct <== //
      let speakerObj = [
        { gender: "male", level: 1, audio: formData["ML1 Speaker Audio_File"], person: formData["ML1 Speaker"] },
        { gender: "male", level: 2, audio: formData["ML2 Speaker Audio_File"], person: formData["ML2 Speaker"] },
        { gender: "female", level: 1, audio: formData["FL1 Speaker Audio_File"], person: formData["FL1 Speaker"] },
        { gender: "female", level: 2, audio: formData["FL2 Speaker Audio_File"], person: formData["FL2 Speaker"] },
      ];
      setformData({
        ...formData,
        speakers: speakerObj
      });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let genderOptions = formatOptionsForDropdown(["Male", "Female"]);

  let WordsSubcategory = [];
  if (fireStoreData?.options?.WordsSubcategory) {
    WordsSubcategory = formatOptionsForDropdown(
      fireStoreData.options.WordsSubcategory.options
    );
  }

  let WordsGrammaticalCategory = [];
  if (fireStoreData?.options?.WordsGrammaticalCategory) {
    WordsGrammaticalCategory = formatOptionsForDropdown(
      fireStoreData.options.WordsGrammaticalCategory.options
    );
  }

  let WordsGrammaticalSubclass = [];
  if (fireStoreData?.options?.WordsGrammaticalSubclass) {
    WordsGrammaticalSubclass = formatOptionsForDropdown(
      fireStoreData.options.WordsGrammaticalSubclass.options
    );
  }

  let WordsCategory = [];
  if (fireStoreData?.options?.WordsCategory) {
    WordsCategory = formatOptionsForDropdown(
      fireStoreData.options.WordsCategory.options
    );
  }

  let WordsSUBPlurality = [];
  if (fireStoreData?.options?.WordsSUBPlurality) {
    WordsSUBPlurality = formatOptionsForDropdown(
      fireStoreData.options.WordsSUBPlurality.options
    );
  }

  let WordsSUBPerson = [];
  if (fireStoreData?.options?.WordsSUBPerson) {
    WordsSUBPerson = formatOptionsForDropdown(
      fireStoreData.options.WordsSUBPerson.options
    );
  }

  let tagOptionsDD = [];
  if (fireStoreData?.options?.tags) {
    tagOptionsDD = formatOptionsForDropdown(fireStoreData.options.tags);
  }

  let WordsRootTypeOptionsDD = [];
  if (fireStoreData?.options?.WordsRootType) {
    WordsRootTypeOptionsDD = formatOptionsForDropdown(fireStoreData.options.WordsRootType.options);
  }

  const buttonSaveBarDOM = () => {
    if (dataSaved === null) {
      return (
        <Button
          style={{ marginRight: 0 }}
          color="green"
          onClick={() => setdataSaved(false)}>
          Unchanged
        </Button>
      );
    } else if (dataSaved) {
      return (
        <Button
          style={{ marginRight: 0 }}
          color="green"
          onClick={() => setdataSaved(false)}>
          <Icon name="checkmark" />
          Saved
        </Button>
      );
    } else {
      return (
        <Button
          style={{ marginRight: 0 }}
          color="blue"
          onClick={() => {
            //TODO: save the data for real
            console.log("Data to be saved: ", formData);
            setdataSaved(true)
          }}>
          <Icon name="save" />
          Save
        </Button>
      );
    }
  };

  const handleAddItemTagOption = (_e, { value }) => {
    console.log("===> inside handleAddItemOption ", value);
    Dispatch(setTags({ tag: value }));
  };

  const handleAddItemRootTypeOption = (_e, { value }) => {
    console.log("===> inside handleAddItemRootTypeOption ", value);
    //TODO: add root type to the database options
    // Dispatch(setTags({ tag: value }));
  };

  const speakerFormDOM = ({ speakersObj }) => {
    // this outputs DOM of all spearker objects 
    const sDOMA = speakersObj.map((spkObj, idx) => {
      return (
        <Form.Group key={idx}>
          <Form.Field>
            <label>Speaker {spkObj.level}</label>
            <InputControlled
              placeholder={spkObj.person || "unknown"}
              showSpecialCharsEntry
            />
          </Form.Field>
          <Form.Field>
            <label>Gender</label>
            <Dropdown placeholder={spkObj.gender} minCharacters={4} selection options={genderOptions}></Dropdown>
          </Form.Field>
          <Form.Field>
            <label>Audio File</label>
            <SimpleMediaPreview mediaObj={spkObj.audio} mediaType="audio" callbackFunc={fileuploadCB} idx={idx} />
          </Form.Field>
        </Form.Group>
      )
    });
    return (sDOMA);
  }

  const fileuploadCB = ({ filestatus, msg, done }) => {
    if (done) {
      console.log("file upload compleate");
    }
    console.log("i got this from the file upload: ", msg);
  }

  return (
    <div className="dictionaryCollection-main">
      {/* //TODO: this button should not scroll off screen */}
      <div ref={alwaysOnScreenHeader} className="dictionaryCollection-Header">
        {isVisible && buttonSaveBarDOM()}
      </div>
      {!isVisible && (
        <div className="dictionaryCollection-Header-keepOnScreen">
          {buttonSaveBarDOM()}
        </div>
      )}
      <div className="dictionaryCollection-Form">
        <Form>
          <Form.Group inline>
            <Form.Field>
              <Checkbox
                label="Record is active"
                toggle
                checked={formData.recordActive}
                onChange={() => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    recordActive: !formData.recordActive,
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Verified with Native fluent speaker"
                toggle
                onChange={() => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    'Verified w/L1?': !formData['Verified w/L1?'],
                  });
                }}
                checked={formData['Verified w/L1?']}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ position: 'relative' }}>
            <Form.Field>
              <label>{nativeLanguage}</label>
              <InputControlled
                showSpecialCharsEntry
                placeholder="test"
                size="small"
                value={formData.Language}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    Language: d.value,
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>English</label>
              <Input
                size="small"
                value={formData.English}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    English: d.value,
                  });
                }}
              />
            </Form.Field>
            <Form.Field style={{ flexGrow: 1, position: 'absolute', right: 0, top: 0 }}>
              <SimpleMediaPreview
                mediaObj={formData.App_Art}
                mediaType="image"
                mainMedia
              />
              <Image src="" size="small" />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Grammatical Category</label>
              <Dropdown
                value={formData["Grammatical_Category"]}
                minCharacters={6}
                selection
                options={WordsGrammaticalCategory}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    Grammatical_Category: d.value,
                  });
                }}
              ></Dropdown>
            </Form.Field>
            <Form.Field>
              <label>Grammatical Subclass</label>
              <Dropdown
                value={formData["Grammatical.Subclass"]}
                minCharacters={1}
                search
                selection
                options={WordsGrammaticalSubclass}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    "Grammatical.Subclass": d.value,
                  });
                }}
              ></Dropdown>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Tags</label>
              <Dropdown
                search
                minCharacters={2}
                allowAdditions
                selection
                options={tagOptionsDD}
                onAddItem={handleAddItemTagOption}
                multiple
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Root</label>
              <Dropdown
                value={formData.Root}
                search
                minCharacters={3}
                allowAdditions
                selection
                options={rootDD}
              />
            </Form.Field>
            <Form.Field>
              <label>Root Type</label>
              <Dropdown
                search
                minCharacters={3}
                allowAdditions
                selection
                value={formData["Root.Type"]}
                options={WordsRootTypeOptionsDD}
                onAddItem={handleAddItemRootTypeOption}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    "Root.Type": d.value,
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <TextEditor
              label="Notes (Never Displayed to non admin users)"
              data={formData.Notes}
              saveFunc={({ html, raw }) => {
                console.log("===> saved data raw ", raw);
                console.log("===> saved data html ", html);
                setformData({
                  ...formData,
                  Notes: { raw: raw, html: html },
                });
              }}
            ></TextEditor>
          </Form.Group>
          <Form.Group>
            <TextEditor label="Language Notes" data=""></TextEditor>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Category</label>
              <Dropdown
                value={formData.Category}
                search
                minCharacters={3}
                allowAdditions
                selection
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    Category: d.value,
                  });
                }}
                options={WordsCategory}
              />
            </Form.Field>
            <Form.Field>
              <label>SubCategory</label>
              <Dropdown
                value={formData.Subcategory}
                search
                minCharacters={3}
                allowAdditions
                selection
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    Subcategory: d.value,
                  });
                }}
                options={WordsSubcategory}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Subject Plurality</label>
              <Dropdown
                value={formData["SUB.Plurality"]}
                minCharacters={6}
                selection
                options={WordsSUBPlurality}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    "SUB.Plurality": d.value,
                  });
                }}
              ></Dropdown>
            </Form.Field>
            <Form.Field>
              <label>Subject Person</label>
              <Dropdown
                value={formData["SUB.Person"]}
                minCharacters={3}
                selection
                // allowAdditions
                options={WordsSUBPerson}
                onChange={(_e, d) => {
                  setdataSaved(false);
                  setformData({
                    ...formData,
                    "SUB.Person": d.value,
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Morphological Breakdown</label>
              <InputControlled
                value={formData["Morphological Breakdown"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <label>Verified</label>
              <Checkbox toggle checked={true} />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Interlinear Gloss</label>
              <InputControlled
                value={formData["Interlinear Gloss"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <Checkbox label="Verified with L1" toggle checked={true} />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <label>Speaker</label>
              <InputControlled
                value={formData["Interlinear Gloss"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <label>Gender</label>
              <Dropdown
                value=""
                minCharacters={4}
                selection
                options={genderOptions}
              ></Dropdown>
            </Form.Field>
          </Form.Group>
          {/* Speakers */}
          {formData.speakers &&
            speakerFormDOM({ speakersObj: formData.speakers })}
          {/* END Spearkers */}
          {/* <Form.Group inline>
            <Form.Field>
              <label>Interlinear Gloss</label>
              <InputControlled
                value={formData["Interlinear Gloss"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <Checkbox label="Verified with L1" toggle checked={true} />
            </Form.Field>
          </Form.Group> */}
          <Form.Group inline>
            <Form.Field>
              <label>Interlinear Gloss</label>
              <InputControlled
                value={formData["Interlinear Gloss"]}
                showSpecialCharsEntry
              />
            </Form.Field>
            <Form.Field>
              <Checkbox label="Verified with L1" toggle checked={true} />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default WordCollection;
