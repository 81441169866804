import "./index.scss";
import React from "react";
import { Menu } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const LeftBar = (props) => {
  return (
    <div className='left-bar'>
      <Menu vertical pointing>
        <Menu.Item
          active={props.currentModule === 'Module 1'}
          name="Module 1"
          onClick={() => props.setCurrentModule('Module 1')}>
          Module 1
        </Menu.Item>
        <Menu.Item
          active={props.currentModule === 'Module 2'}
          name="Module 2"
          onClick={() => props.setCurrentModule('Module 2')}>
          Module 2
        </Menu.Item>
        <Menu.Item
          active={props.currentModule === 'Module 3'}
          name="Module 3"
          onClick={() => props.setCurrentModule('Module 3')}>
          Module 3
        </Menu.Item>
        <Menu.Item
          active={props.currentModule === 'Module 4'}
          name="Module 4"
          onClick={() => props.setCurrentModule('Module 4')}>
          Module 4
        </Menu.Item>
        <Menu.Item
          active={props.currentModule === 'Module 5'}
          name="Module 5"
          onClick={() => props.setCurrentModule('Module 5')}>
          Module 5
        </Menu.Item>
        <Menu.Item
          active={props.currentModule === 'Module 6'}
          name="Module 6"
          onClick={() => props.setCurrentModule('Module 6')}>
          Module 6
        </Menu.Item>
      </Menu>
    </div>
  );

}

export default LeftBar;

